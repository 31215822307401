<template>
  <div>
    <label class="mb-3">{{label}}</label>
    <b-form-group class="z-form-group">
      <b-form-radio v-for="(o, i) in options" :key="i" v-model="value.$model" :value="o.value" class="mr-4">
        {{ o.label }}
      </b-form-radio>
    </b-form-group>
    <span v-if="value.$dirty && value.$invalid" class="text-danger">Je nutné vybrať jednu z možností.</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    }
  }
}
</script>